import { AxiosError } from 'axios';
import http, { Http } from '@/lib/http';

type estimateCustomPaymentData = {
  orderId: string;
  projectId: string;
  amountInCents: number;
};

export type Installment = {
  id: string;
  amountInCents: number;
  dueDate: string;
  status: string;
  currency: string;
};

export type EstimateCustomPayment = {
  nextDueInstallment: Installment | undefined;
  remainingAmountInCents: number;
  customPaymentMessage: string;
};

function estimateCustomPaymentCtor(http: Http) {
  return async ({ orderId, projectId, amountInCents }: estimateCustomPaymentData): Promise<EstimateCustomPayment> => {
    try {
      const { data } = await http.post<EstimateCustomPayment>(
        `/projects/${projectId}/off-schedule-payment/${orderId}/estimate-custom-payment`,
        { amountInCents },
      );
      return data;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };
}

export const estimateCustomPayment = estimateCustomPaymentCtor(http);
